import { useState, useEffect, useRef } from 'react';

export const useIntersectionObserver = (options) => {

  const containerRef = useRef(null);

  const [isVisible, setVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [ entry ] = entries;
    setVisible(entry.isIntersecting);
  };

  useEffect(() => {
    let observer;
    if (process.browser) {
      observer = new IntersectionObserver(callbackFunction, options)
      if (containerRef.current && observer) observer.observe(containerRef.current);
    }

    return () => {
      if(containerRef.current && observer) observer.unobserve(containerRef.current);
    }
  }, [containerRef, options]);

  return [containerRef, isVisible];
};
