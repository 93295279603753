import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import { connect } from "react-redux";
import { useAuth } from "../../providers/Auth";
import { AGENT_CONSTANTS } from "../../client/modules/agents/constants/agent.constants";
import {
  ASURANSI_MOBIL_ICON,
  ASURANSI_KESEHATAN_ICON,
  ASURANSI_KARYAWAN_ICON,
  ASURANSI_MOTOR_ICON,
} from "../../shared/constants";
import "./HomepageContainer.module.scss";
import cookies from "js-cookie";
import { useIntersectionObserver } from "../../client/utils/useIntersectionObserver";
import qs from 'query-string';

const Footer = dynamic(() => import("./Footer"), { ssr: true, });
const HomepageAssociatedBrands = dynamic(() => import("./HomepageAssociatedBrands"), { ssr: true, });
const HomepageFAQ = dynamic(() => import("./HomepageFAQ"), { ssr: true, });
const HomepageGoogleReview = dynamic(() => import("./HomepageGoogleReview"), { ssr: true, });
const HomepageMainHero = dynamic(() => import("./HomepageMainHero"), { ssr: true, });
const HomepageMedia = dynamic(() => import("./HomepageMedia"), { ssr: true, });
const HomepagePromo = dynamic(() => import("./HomepagePromo"), { ssr: true, });
const HomepageRecommendedArticles = dynamic(() => import("./HomepageRecommendedArticles"), { ssr: true, });
const HomepageWhyLifepal = dynamic(() => import("./HomepageWhyLifepal"), { ssr: true, });
const Meta = dynamic(() => import("../../components/Meta"));
const StickyWhatsapp = dynamic(() => import("../../components/pages/Landing/StickyWhatsapp"), { ssr: true, });

const { APP_ENV } = getConfig().publicRuntimeConfig;
const detectWebPSupport = () =>
  new Promise((res) => {
    const webP = new Image();
    webP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    webP.onload = webP.onerror = () => {
      res(webP.height === 2);
    };
  });

const HomepageContainer = ({ dispatch }) => {
  const { authToken } = useAuth();
  const router = useRouter();

  const [isDesktop, setIsDesktop] = useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [hasWEBPSupport, setWEBPSupport] = React.useState("");
  const mediaSliderOptions = {
    root: process.browser
      ? document.querySelector(".media__sprite--image")
      : null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  useEffect(() => {
    setIsDesktop(window.innerWidth >= 768);
  }, []);

  const [_, isMediaSliderVisible] =
    useIntersectionObserver(mediaSliderOptions);

  useEffect(() => {
    if (isMediaSliderVisible) {
      let slideList = document.querySelectorAll(".media__sprite--image");
      Array.from(slideList).map((slideItem) => {
        slideItem.style.backgroundImage = `url(${hasWEBPSupport
          ? "/static/images/media/media.webp"
          : "/static/images/media/media.png"
          })`;
      });
    }
  }, [isMediaSliderVisible]);

  useEffect(() => {
    cookies.remove("phoneNumber");
    detectWebPSupport().then((hasWebPSupport) => {
      if (hasWebPSupport) {
        document.documentElement.classList.remove("no-webp");
        setWEBPSupport(true);
      } else {
        document.documentElement.classList.remove("has-webp");
      }
    });
  }, []);

  // useEffect authToken
  useEffect(() => {
    if (authToken) {
      const agent = AGENT_CONSTANTS.getAgentByToken(authToken);
      if (
        agent.isExternalAgent ||
        agent.isInternalAgent ||
        agent.isTSOSupervisor
      )
        return;

      dispatch({
        type: "GET_USER_PROFILE",
        payload: { token: authToken },
        callback: (profiles) => {
          if (profiles && profiles.length) {
            const mainProfile = profiles.find((row) => row.mainProfile);

            if (mainProfile) {
              setUserProfile(mainProfile);

              const identityNumber =
                mainProfile.passportNumber ||
                mainProfile.sourceOfFund ||
                mainProfile.monthlyIncome ||
                mainProfile.identityCardNumber ||
                "";
            }
          }
        },
      });
    }
  }, [authToken]);

  const handleHeroCategoryClick = ({ authToken, category, url }) => {
    let pushUrl = "/asuransi";

    if (authToken) {
      if (userProfile && userProfile.fullName && userProfile.phoneNumber) {
        const trackerPayload = {
          phone_number: userProfile.phoneNumber,
          category: category,
        };

        dispatch({
          type: "CREATE_QUEUE_ENTRY",
          payload: {
            data: trackerPayload,
          },
          callback: (response) => {
            if (response && response.data && response.data.id) {
              localStorage.setItem("queueId", response.data.id);
            }
          },
        });

        const query = {
          category: category,
          step: 1,
          name: userProfile.fullName,
          phoneNumber: userProfile.phoneNumber,
          has_credit_card: false,
        };

        pushUrl = `/search?${qs.stringify(query)}`;
      }
    } else {
      pushUrl = url;
    }

    router.push(pushUrl);
  };

  return (
    <>
      <Meta
        title="Lifepal: Bandingkan Asuransi Terbaik dengan Premi Termurah"
        meta={[
          <meta
            key="description"
            name="description"
            content="Bandingkan berbagai pilihan polis terbaik secara online di marketplace asuransi Lifepal. Dapatkan promo menarik dan premi paling terjangkau!"
          />,
          <meta
            key="keywords"
            name="keywords"
            content="asuransi, asuransi kesehatan, asuransi jiwa"
          />,
          <meta
            key="ogimage"
            property="og:image"
            content="/static/images/lifepal-logo-square.jpg"
          />,
          <link
            key="canonical"
            rel="canonical"
            href={
              APP_ENV === "production"
                ? "https://lifepal.co.id"
                : "https://lifepal.tech/"
            }
          />,
          APP_ENV !== "develop" && (
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7440190460548738"
              crossOrigin="anonymous"
            />
          ),
        ]}
      />
      <div className="homepage-wrapper">
        <HomepageMainHero
          items={[
            {
              icon: ASURANSI_MOBIL_ICON,
              title: "Asuransi Mobil",
              onClick: () =>
                handleHeroCategoryClick({
                  authToken,
                  category: "auto",
                  url: "/asuransi/mobil/",
                }),
            },
            {
              icon: ASURANSI_KESEHATAN_ICON,
              title: "Asuransi Kesehatan",
              onClick: () =>
                handleHeroCategoryClick({
                  authToken,
                  category: "health",
                  url: "/asuransi/kesehatan/",
                }),
            },
            {
              icon: ASURANSI_KARYAWAN_ICON,
              title: "Asuransi Karyawan",
              onClick: () => {
                if (authToken) {
                  window.location.href = "https://app.lifepal.co.id/";
                } else {
                  handleHeroCategoryClick({
                    url: "/asuransi/karyawan/",
                  });
                }
              },
            },
            {
              icon: ASURANSI_MOTOR_ICON,
              title: "Asuransi Motor",
              onClick: () =>
                handleHeroCategoryClick({
                  authToken,
                  category: "moto",
                  url: "/asuransi/motor/",
                }),
            },
          ]}
        />
        <HomepageWhyLifepal />
        <HomepageAssociatedBrands isDesktop={isDesktop} />
        <HomepagePromo />
        <HomepageRecommendedArticles isDesktop={isDesktop} />
        <HomepageGoogleReview />
        <HomepageMedia />
        <HomepageFAQ />
        <StickyWhatsapp />
        <Footer />
      </div>
    </>
  );
};

export default connect()(HomepageContainer);
