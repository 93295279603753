import React from 'react'
import HomepageContainer from '../containers/homepage/HomepageContainer';

const Homepage = () => {

  return (
    <HomepageContainer />
  );
}

export default Homepage;